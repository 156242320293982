exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-add-business-js": () => import("./../../../src/pages/add-business.js" /* webpackChunkName: "component---src-pages-add-business-js" */),
  "component---src-pages-all-business-location-js": () => import("./../../../src/pages/all-business-location.js" /* webpackChunkName: "component---src-pages-all-business-location-js" */),
  "component---src-pages-best-doctors-list-in-south-africa-js": () => import("./../../../src/pages/best-doctors-list-in-south-africa.js" /* webpackChunkName: "component---src-pages-best-doctors-list-in-south-africa-js" */),
  "component---src-pages-best-financial-activity-js": () => import("./../../../src/pages/best-financial-activity.js" /* webpackChunkName: "component---src-pages-best-financial-activity-js" */),
  "component---src-pages-best-hotels-in-south-africa-js": () => import("./../../../src/pages/best-hotels-in-south-africa.js" /* webpackChunkName: "component---src-pages-best-hotels-in-south-africa-js" */),
  "component---src-pages-best-insurance-in-south-africa-js": () => import("./../../../src/pages/best-insurance-in-south-africa.js" /* webpackChunkName: "component---src-pages-best-insurance-in-south-africa-js" */),
  "component---src-pages-best-intertainment-in-south-africa-js": () => import("./../../../src/pages/best-intertainment-in-south-africa.js" /* webpackChunkName: "component---src-pages-best-intertainment-in-south-africa-js" */),
  "component---src-pages-best-lawyers-js": () => import("./../../../src/pages/best-lawyers.js" /* webpackChunkName: "component---src-pages-best-lawyers-js" */),
  "component---src-pages-best-properties-agent-in-south-africa-js": () => import("./../../../src/pages/best-properties-agent-in-south-africa.js" /* webpackChunkName: "component---src-pages-best-properties-agent-in-south-africa-js" */),
  "component---src-pages-best-restaurants-in-south-africa-js": () => import("./../../../src/pages/best-restaurants-in-south-africa.js" /* webpackChunkName: "component---src-pages-best-restaurants-in-south-africa-js" */),
  "component---src-pages-best-school-in-south-africa-js": () => import("./../../../src/pages/best-school-in-south-africa.js" /* webpackChunkName: "component---src-pages-best-school-in-south-africa-js" */),
  "component---src-pages-best-shopping-center-js": () => import("./../../../src/pages/best-shopping-center.js" /* webpackChunkName: "component---src-pages-best-shopping-center-js" */),
  "component---src-pages-best-vehicle-services-in-south-africa-js": () => import("./../../../src/pages/best-vehicle-services-in-south-africa.js" /* webpackChunkName: "component---src-pages-best-vehicle-services-in-south-africa-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-business-added-js": () => import("./../../../src/pages/business-added.js" /* webpackChunkName: "component---src-pages-business-added-js" */),
  "component---src-pages-business-listing-in-alberton-js": () => import("./../../../src/pages/business-listing-in-alberton.js" /* webpackChunkName: "component---src-pages-business-listing-in-alberton-js" */),
  "component---src-pages-business-listing-in-benoni-js": () => import("./../../../src/pages/business-listing-in-benoni.js" /* webpackChunkName: "component---src-pages-business-listing-in-benoni-js" */),
  "component---src-pages-business-listing-in-bloemfontein-js": () => import("./../../../src/pages/business-listing-in-bloemfontein.js" /* webpackChunkName: "component---src-pages-business-listing-in-bloemfontein-js" */),
  "component---src-pages-business-listing-in-boksburg-js": () => import("./../../../src/pages/business-listing-in-boksburg.js" /* webpackChunkName: "component---src-pages-business-listing-in-boksburg-js" */),
  "component---src-pages-business-listing-in-cape-town-js": () => import("./../../../src/pages/business-listing-in-cape-town.js" /* webpackChunkName: "component---src-pages-business-listing-in-cape-town-js" */),
  "component---src-pages-business-listing-in-centurion-js": () => import("./../../../src/pages/business-listing-in-centurion.js" /* webpackChunkName: "component---src-pages-business-listing-in-centurion-js" */),
  "component---src-pages-business-listing-in-durban-js": () => import("./../../../src/pages/business-listing-in-durban.js" /* webpackChunkName: "component---src-pages-business-listing-in-durban-js" */),
  "component---src-pages-business-listing-in-east-london-js": () => import("./../../../src/pages/business-listing-in-east-london.js" /* webpackChunkName: "component---src-pages-business-listing-in-east-london-js" */),
  "component---src-pages-business-listing-in-germiston-js": () => import("./../../../src/pages/business-listing-in-germiston.js" /* webpackChunkName: "component---src-pages-business-listing-in-germiston-js" */),
  "component---src-pages-business-listing-in-johannesburg-js": () => import("./../../../src/pages/business-listing-in-johannesburg.js" /* webpackChunkName: "component---src-pages-business-listing-in-johannesburg-js" */),
  "component---src-pages-business-listing-in-kempton-park-js": () => import("./../../../src/pages/business-listing-in-kempton-park.js" /* webpackChunkName: "component---src-pages-business-listing-in-kempton-park-js" */),
  "component---src-pages-business-listing-in-mbombela-js": () => import("./../../../src/pages/business-listing-in-mbombela.js" /* webpackChunkName: "component---src-pages-business-listing-in-mbombela-js" */),
  "component---src-pages-business-listing-in-midrand-js": () => import("./../../../src/pages/business-listing-in-midrand.js" /* webpackChunkName: "component---src-pages-business-listing-in-midrand-js" */),
  "component---src-pages-business-listing-in-pietermaritzburg-js": () => import("./../../../src/pages/business-listing-in-pietermaritzburg.js" /* webpackChunkName: "component---src-pages-business-listing-in-pietermaritzburg-js" */),
  "component---src-pages-business-listing-in-port-elizabeth-js": () => import("./../../../src/pages/business-listing-in-port-elizabeth.js" /* webpackChunkName: "component---src-pages-business-listing-in-port-elizabeth-js" */),
  "component---src-pages-business-listing-in-pretoria-js": () => import("./../../../src/pages/business-listing-in-pretoria.js" /* webpackChunkName: "component---src-pages-business-listing-in-pretoria-js" */),
  "component---src-pages-business-listing-in-randburg-js": () => import("./../../../src/pages/business-listing-in-randburg.js" /* webpackChunkName: "component---src-pages-business-listing-in-randburg-js" */),
  "component---src-pages-business-listing-in-roodepoort-js": () => import("./../../../src/pages/business-listing-in-roodepoort.js" /* webpackChunkName: "component---src-pages-business-listing-in-roodepoort-js" */),
  "component---src-pages-business-listing-in-sandton-js": () => import("./../../../src/pages/business-listing-in-sandton.js" /* webpackChunkName: "component---src-pages-business-listing-in-sandton-js" */),
  "component---src-pages-business-listing-in-soweto-js": () => import("./../../../src/pages/business-listing-in-soweto.js" /* webpackChunkName: "component---src-pages-business-listing-in-soweto-js" */),
  "component---src-pages-business-listing-in-thembisa-js": () => import("./../../../src/pages/business-listing-in-thembisa.js" /* webpackChunkName: "component---src-pages-business-listing-in-thembisa-js" */),
  "component---src-pages-business-updated-js": () => import("./../../../src/pages/business-updated.js" /* webpackChunkName: "component---src-pages-business-updated-js" */),
  "component---src-pages-bussiness-listing-in-polokwane-js": () => import("./../../../src/pages/bussiness-listing-in-polokwane.js" /* webpackChunkName: "component---src-pages-bussiness-listing-in-polokwane-js" */),
  "component---src-pages-coffee-shop-js": () => import("./../../../src/pages/coffee-shop.js" /* webpackChunkName: "component---src-pages-coffee-shop-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cookies-policy-js": () => import("./../../../src/pages/cookies-policy.js" /* webpackChunkName: "component---src-pages-cookies-policy-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-dismissal-js": () => import("./../../../src/pages/dismissal.js" /* webpackChunkName: "component---src-pages-dismissal-js" */),
  "component---src-pages-dry-cleaners-js": () => import("./../../../src/pages/dry-cleaners.js" /* webpackChunkName: "component---src-pages-dry-cleaners-js" */),
  "component---src-pages-effective-strategies-for-healthy-weight-loss-js": () => import("./../../../src/pages/effective-strategies-for-healthy-weight-loss.js" /* webpackChunkName: "component---src-pages-effective-strategies-for-healthy-weight-loss-js" */),
  "component---src-pages-effective-strategies-to-grow-your-business-online-js": () => import("./../../../src/pages/effective-strategies-to-grow-your-business-online.js" /* webpackChunkName: "component---src-pages-effective-strategies-to-grow-your-business-online-js" */),
  "component---src-pages-effective-strategies-to-make-money-online-legitimately-js": () => import("./../../../src/pages/effective-strategies-to-make-money-online-legitimately.js" /* webpackChunkName: "component---src-pages-effective-strategies-to-make-money-online-legitimately-js" */),
  "component---src-pages-fashion-and-beauty-js": () => import("./../../../src/pages/fashion-and-beauty.js" /* webpackChunkName: "component---src-pages-fashion-and-beauty-js" */),
  "component---src-pages-finance-and-money-saving-js": () => import("./../../../src/pages/finance-and-money-saving.js" /* webpackChunkName: "component---src-pages-finance-and-money-saving-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-job-recruiters-in-south-africa-js": () => import("./../../../src/pages/job-recruiters-in-south-africa.js" /* webpackChunkName: "component---src-pages-job-recruiters-in-south-africa-js" */),
  "component---src-pages-login-here-js": () => import("./../../../src/pages/login-here.js" /* webpackChunkName: "component---src-pages-login-here-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-manage-business-js": () => import("./../../../src/pages/manage-business.js" /* webpackChunkName: "component---src-pages-manage-business-js" */),
  "component---src-pages-mtn-bundle-bonanza-js": () => import("./../../../src/pages/mtn-bundle-bonanza.js" /* webpackChunkName: "component---src-pages-mtn-bundle-bonanza-js" */),
  "component---src-pages-pothole-patching-js": () => import("./../../../src/pages/pothole-patching.js" /* webpackChunkName: "component---src-pages-pothole-patching-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-python-developer-intern-js": () => import("./../../../src/pages/python-developer-Intern.js" /* webpackChunkName: "component---src-pages-python-developer-intern-js" */),
  "component---src-pages-recent-jobs-in-sa-js": () => import("./../../../src/pages/recent-jobs-in-sa.js" /* webpackChunkName: "component---src-pages-recent-jobs-in-sa-js" */),
  "component---src-pages-registration-js": () => import("./../../../src/pages/registration.js" /* webpackChunkName: "component---src-pages-registration-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-spar-rewards-js": () => import("./../../../src/pages/spar-rewards.js" /* webpackChunkName: "component---src-pages-spar-rewards-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-travel-and-adventure-js": () => import("./../../../src/pages/travel-and-adventure.js" /* webpackChunkName: "component---src-pages-travel-and-adventure-js" */),
  "component---src-pages-update-business-js": () => import("./../../../src/pages/update-business.js" /* webpackChunkName: "component---src-pages-update-business-js" */),
  "component---src-pages-view-js": () => import("./../../../src/pages/view.js" /* webpackChunkName: "component---src-pages-view-js" */)
}

